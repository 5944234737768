<template>
    <modal
        transition="nice-modal-fade"
        class="new-modal-default"
        :name="modal_name"
        :delay="100"
        height="auto"
        :adaptive="true"
        :scrollable="false"
    >
        <div class="v-modal-content" style="border-radius: 15px;background-color: #fff !important;">
            <div class="v-modal-header d-flex align-items-center justify-content-center position-relative">
                <div v-if="bulk_share == true">
                    <span class="v-modal-dialog-title v-modal-title text-uppercase" style="color: #fff !important;">Send survey info</span>
                </div>
                <div v-else>
                    <span class="v-modal-dialog-title v-modal-title text-uppercase" style="color: #fff !important;">Share Survey</span>
                </div>
                <div class="position-absolute" style="right: 0;" @click="HideSendSurveyInfo()">
                    <span class="icon icon-close mr-2 text-white pointer close-left"></span>
                </div>
            </div>
            <div class="v-modal-body">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <span class="fs-14 fw-600 px-2" style="color: #0475f3;">
                            Email *
                        </span>
                        <div class="d-flex align-items-center">
                            <vue-tags-input v-model="email_list" style="max-width: 100% !important;" class="inputFieldNew mt-3" placeholder="Enter email id here" name="to_email" id='to_email' :tags="email_lists" @tags-changed="newEmails => email_lists = newEmails" />
                        </div>
                        <div class="d-flex justify-content-end pt-2"  v-if="to_email_error == false">
                            <!-- <a class="decoration-none" @click="bulkShare()" href="javascript:;">+ Bulk Share</a> -->
                        </div>
                        <div class="d-flex justify-content-between pt-2"  v-if="to_email_error">
                            <span class="invalid-feedback-form text-danger w-80 mt-0 text-left d-block" v-if="to_email_error">Email is Required</span>
                            <!-- <a class="decoration-none" @click="bulkShare()" href="javascript:;">+ Bulk Share</a> -->
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12" v-if="bulk_share == true">
                        <div style="border:1px solid #3f3f3f;" class="p-3">
                            <div class="d-flex justify-content-between">
                                <span class="fs-14 fw-600 px-2">
                                Add People</span>
                                <div class="d-flex flex-column">
                                    <button class="btn btn-success text-white" style="border-radius: 5px !important">Save</button>
                                    
                                </div>
                            </div>
                                <div class="d-flex justify-content-between mt-2 mb-1">
                                    <div>
                                        <button class="btn-choice fs-14 fw-600 px-2 pointer" :class="[invite_people == true ? 'bulkShare-choice' : '']" @click="bulkShare_choice('invite_people')">Invite People</button>
                                        <button class="btn-choice fs-14 fw-600 px-2 pointer" :class="[share_with_team == true ? 'bulkShare-choice' : '']" @click="bulkShare_choice('share_with_team')">Share with Team</button>
                                        <button class="btn-choice fs-14 fw-600 px-2 pointer" :class="[group == true ? 'bulkShare-choice' : '']" @click="bulkShare_choice('group')">Group</button>
                                    </div>
                                    <div>
                                        <a href="#" class="decoration-none mt-3 fs-14 fw-600 px-2 text-center">Delete All</a>
                                    </div>
                                </div>  
                                <div v-if="invite_people || share_with_team" style="max-height:100px;overflow-y:auto">
                                    <ul class="list-group"> 
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content">
                                                    <h4 class="text-truncate fs-14 fw-600">John</h4>
                                                    <h4 class="text-truncate fs-14 fw-600">john@digicollect.com</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content">
                                                    <h4 class="text-truncate fs-14 fw-600">John</h4>
                                                    <h4 class="text-truncate fs-14 fw-600">john@digicollect.com</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content">
                                                    <h4 class="text-truncate fs-14 fw-600">John</h4>
                                                    <h4 class="text-truncate fs-14 fw-600">john@digicollect.com</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content">
                                                    <h4 class="text-truncate fs-14 fw-600">John</h4>
                                                    <h4 class="text-truncate fs-14 fw-600">john@digicollect.com</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content">
                                                    <h4 class="text-truncate fs-14 fw-600">John</h4>
                                                    <h4 class="text-truncate fs-14 fw-600">john@digicollect.com</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="group" style="max-height:100px;overflow-y:auto">
                                    <ul class="list-group"> 
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content d-flex align-items-center">
                                                    <h4 class="fs-14 fw-600">IBM Client</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content d-flex align-items-center">
                                                    <h4 class="fs-14 fw-600">Sunpower</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content d-flex align-items-center">
                                                    <h4 class="fs-14 fw-600">John</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content d-flex align-items-center">
                                                    <h4 class="fs-14 fw-600">John</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                        <li class="list-group-item d-flex justify-content-between">
                                            <div class="d-flex">
                                                <div class="avatar mr-4">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png"
                                                    style="max-width:40px;max-height:40px;" alt="">
                                                </div>
                                                <div class="list-item-content d-flex align-items-center">
                                                    <h4 class="fs-14 fw-600">John</h4>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <i class="icon icon-right icon-pencil"></i>
                                                <i class="icon icon-right icon-delete ml-2"></i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12">
                                        <div class="d-flex align-items-center">
                                            <input name="email_or_name" v-validate="'required'"
                                                class="inputFieldNew-border-bottom mt-3" type="text"
                                                placeholder="Invite by Entering Email or Name" />
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-end mt-2">
                                    <button class="btn btn-secondary" @click="SelectContactModal()" style="border-radius: 5px !important">+ Add</button>
                                </div>
                        </div>
                    </div>    

                    <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                        <span class="fs-14 fw-600 px-2" style="color: #0475f3;">
                            Message
                        </span>
                        <div class="d-flex align-items-center mt-3">
                            <textarea placeholder="Add Message" v-model="message"
                                style="padding-top: 6px !important; width: 100%; background: transparent; color: #303031; border-radius: 5px; border: 0.6px solid #676767 !important; resize: none; height: 8em; padding: 10px; overflow-y: auto;padding-right: 75px !important;"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="v-modal-footer">
                <div class="d-flex justify-content-center p-3">
                    <button class="btn btn-outline-secondary" @click="HideSendSurveyInfo()">Cancel</button>
                    <button class="btn send-btn btn-secondary ml-3" @click="submit">Send</button>
                </div>
                
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{success_msg}}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{warning_msg}}</sweet-modal>
        <select-contact @hide-select-contact-modal="HideSelectContact" v-if="select_contact" modal_name="Select-Contact-Modal"></select-contact>
    </modal>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
const SelectContact = () => import('./SelectContact');
import { SweetModal } from 'sweet-modal-vue';
import survey_forms from '../mixins/survey_forms';
export default {
    props: ['modal_name', 'form_id'],
    mixins: [survey_forms],
    components: {
        VueTagsInput,
        SelectContact,
        SweetModal,
    },
    data(){
        return{
            bulk_share : false,
            invite_people: true,
            share_with_team: false,
            group: false,
            message: '',
            email_list: '',
            email_lists: [],
            to_email_error: false,
            select_contact: false,
            success_msg: '',
            warning_msg: '',
            warning_modal:'',
        }
    },
    methods: {
        HideSendSurveyInfo(){
            this.$emit('hide-send-survey-info')
        },
        bulkShare(){
            this.bulk_share = !this.bulk_share
        },
        bulkShare_choice(obj){
            if(obj == "invite_people"){
                this.invite_people = true;
                this.share_with_team = false;
                this.group = false;
            }else if(obj == "share_with_team"){
                this.invite_people = false;
                this.share_with_team = true;
                this.group = false;
            }else if(obj == "group"){
                this.invite_people = false;
                this.share_with_team = false;
                this.group = true;
            }
        },
        submit() {
            if(this.email_lists.length > 0) {
                this.to_email_error = false;
                this.email_lists.forEach((element, index) => {
                    this.email_lists[index] = element.text;
                });
                this.sendSurvey();
            }
            else {
                this.to_email_error = true;
            }
        },
        async sendSurvey(){
            try{
                let response = await this.sendSurveyForm({
                    form_id: this.form_id,
                    email_list: this.email_lists,
                    message: this.message
                });
                this.success_msg = response.message;
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    window.location.reload();
                }, 2000);
            }
            catch(error){
                this.warning_msg = error;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                }, 2000);
            }
        },
        SelectContactModal(){
            this.select_contact = true
            setTimeout(() => {
                this.$modal.show('Select-Contact-Modal')
            }, 500)
        },
        HideSelectContact(){
            this.select_contact = false
        }
    },
    mounted() {
        // console.log(this.form_id);
    },
    watch: {
        email_lists: function (val) {
            if(val.length>0) {
                this.to_email_error = false;
            }
        },
    },
};

</script>

<style scoped>
    .w-80{
        width:80%;
    }
    .send-btn{
        border-radius: 5px !important;
    }
    .decoration-none{
        text-decoration:none;
    }
    .inputFieldNew-border-bottom {
        font-size: 14px !important;
        padding: 10px 11px 7px 8px !important;
        display: block;
        width: 100%;
        border: none;
        border-bottom: solid 1px #707070 !important;
        background: transparent !important;
        color: #303031;
        font-weight: 600;
        margin-top: 20px;
    }
    .btn-choice{
        outline: none;
    }
    .bulkShare-choice{
        color: #0475f3 !important;
        text-decoration: underline !important;
    }
    .icon-right{
        color: #0475f3 !important;
    }
</style>